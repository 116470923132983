<template>
  <div class="blackscreen">
    <div class="loading-container" v-if="!$store.state.loadError">
      <h2>Chargement de l'expérience</h2>
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="error-container" v-else>
      <h2>Une erreur s'est produite</h2>
      <div @click="reloadPage">
        <h3>Cliquez ici pour recharger la page</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingAndErrorScreen",
  methods: {
    reloadPage() {
      window.open(window.origin,"_self");
    }
  }
};
</script>

<style scoped lang="scss">
.blackscreen {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    h2 {
      color: white;
      text-align: center;
    }

    .lds-ellipsis {
      display: inline-block;
      position: relative;
      top: 5px;
      left: 10px;
      width: 80px;
      height: 80px;
    }
    .lds-ellipsis div {
      position: absolute;
      top: 33px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: #fff;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(24px, 0);
      }
    }
  }

  .error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      color: darkred;
      text-align: center;
      margin-bottom: 3vh;
    }
    h3 {
      color: white;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import CloseButton from "@/components/Atoms/CloseButton";

const routes = [
  {
    path: "/membre/:member",
    name: "members",
    component: () => import("../components/RightPanelComponents/teamMember"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../components/RightPanelComponents/home"),
  },
  {
    path: "/team",
    name: "team",
    component: () => import("../components/RightPanelComponents/teamPage"),
  },
  {
    path: "/services/:service",
    name: "services",
    component: () => import("../components/RightPanelComponents/UxStep"),
  },
  {
    path: "/projets/:project",
    name: "projects",
    component: () => import("../components/RightPanelComponents/projetPage"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: CloseButton,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  router.app.$store.commit("goToPositionName", to.path);
});

export default router;

<template>
  <div class="lower-panel" :class="{ opened: $store.state.showLowerPanel }">
    <img
      class="close-btn"
      src="../../assets/icons/white_close_btn.svg"
      alt="Close Icon"
      @click="resetAndClosePanel"
    />
    <JobApplication v-show="$store.state.jobPanelIsActive" />
    <Contact v-show="!$store.state.jobPanelIsActive"  />
  </div>
</template>

<script>
import Contact from "@/components/LowerPanelComponents/Contact";
import JobApplication from "@/components/LowerPanelComponents/JobApplication";
export default {
  name: "LowerPanel",
  components: { JobApplication, Contact },
  methods: {
    resetAndClosePanel() {
      this.$store.commit("showLowerPanel", false);
      setTimeout(() => {
        this.$store.commit("activateJobPanel", false)
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
$height: 100vh;

.lower-panel {
  position: absolute;
  top: 100vh;
  width: 100vw;
  height: $height;
  background-color: $black;
  z-index: 3;
  transition: transform 1s;
  overflow-y: auto;

  .close-btn {
    position: absolute;
    left: 50%;
    top: 2rem;

    &:hover {
      cursor: pointer;
    }
  }
}

.opened {
  transform: translateY(-$height);
  transition: transform 1s;
}

@media screen and (max-width: $small-screen) {
  .lower-panel {
    transition: transform 0.1s;
  }
}

@media screen and (max-width: $xs-screen) {
}
</style>

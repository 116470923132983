<template>
  <div
    v-if="loadedData"
    class="contact-panel"
  >
    <div class="description-container">
      <div class="main-description">
        <p>
          {{ loadedData.intro }}
        </p>
      </div>
      <h2 id="raccoon">{{ loadedData.companyName }}</h2>
      <div id="left-address">
        <img src="../../assets/icons/location_icon.svg" alt="location icon" />
        <p class="p2">
          {{ loadedData.address }}
        </p>
      </div>
      <p class="p2" id="right-address">
        {{ loadedData.contact }}
      </p>
    </div>
    <form class="main-form" @submit="checkForm">
      <div id="first-name">
        <RacInput
          ref="first"
          :label="loadedData.first.label"
          :placeholder="loadedData.first.placeholder"
          :error="loadedData.first.error"
        />
      </div>
      <div id="last-name">
        <RacInput
          ref="last"
          :label="loadedData.last.label"
          :placeholder="loadedData.last.placeholder"
          :error="loadedData.last.error"
        />
      </div>
      <div id="phone">
        <RacInput
          ref="phone"
          :label="loadedData.phone.label"
          :placeholder="loadedData.phone.placeholder"
          :error="loadedData.phone.error"
          :validation-regex="phoneRegex"
        />
      </div>
      <div id="email">
        <RacInput
          ref="email"
          :label="loadedData.email.label"
          :placeholder="loadedData.email.placeholder"
          :error="loadedData.email.error"
          :validation-regex="emailRegex"
        />
      </div>
      <div id="message">
        <RacInput
          ref="message"
          :label="loadedData.message.label"
          :placeholder="loadedData.message.placeholder"
          :error="loadedData.message.error"
          :text-area="true"
          :mandatory="false"
        />
      </div>
      <RacButton
        @click.prevent="validateAndSend"
        :text="buttonText"
        :disabled="formStatus === 2"
      />
      <p class="error p2" :class="{ shown: formStatus === 3 }">
        {{ loadedData.error }}
      </p>
    </form>
  </div>
</template>

<script>
import RacButton from "@/components/Atoms/RacButton";
import RacInput from "@/components/Molecule/RacInput";
import { CONTACT_PAGE_QUERY } from "@/gql-operations/allGqlOperations";

const STATUS = {
  IDLE: 0,
  SENDING: 1,
  SUCCESS: 2,
  ERROR: 3,
};

export default {
  name: "Contact",
  components: { RacInput, RacButton },
  apollo: {
    getData: {
      query: CONTACT_PAGE_QUERY,
      update(data) {
        this.loadedData = data.pages.nodes[0].contactPageFields?.content;
      },
    },
  },
  data() {
    return {
      loadedData: null,
      lowerPanelOpened: true,
      formStatus: STATUS.IDLE,
      phoneRegex: /(^\+|^0{2})\d{11}$|^0\d{9}$/,
      emailRegex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
  },
  methods: {
    validateAndSend() {
      if (this.formStatus === STATUS.SENDING) {
        return;
      }

      let allValid = true;
      for (let ref in this.$refs) {
        this.$refs[ref].validate();
        if (!this.$refs[ref].isValid) {
          allValid = false;
        }
      }
      if (allValid) {
        this.formStatus = STATUS.SENDING;
        // TODO Il faudra que Lionel ou Xavier ou Mehmet fassent la procédure de créer un projet dans la cloud console et de générer des credentials: See this tutorial: https://www.cloudways.com/blog/post-smtp-mailer-fork-of-wordpress-postman-smtp-plugin/?id=339490
        // TODO Ensuite il faudra faire le Wizard SMTP selon le tuto ci-dessus puis aller encore modifier l'adresse "FROM" dans les settings du plugin, Show all settings, "Message", "From adress". Voir: https://wordpress.org/support/topic/5-7-0-authentication-rejected/#post-12963408
        // TODO Il faut peut-être pour ça ajouter hello@raccoon.ch dans "Envoyer des em-mails en tant que" du compte de la personne.
        fetch(`${this.$store.state.websiteLink}/wp/wp-json/v1/contact`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: this.formJSON,
        }).then((response) => {
          this.formStatus =
            response.status === 200 ? STATUS.SUCCESS : STATUS.ERROR;
        });
      }
    },
  },
  computed: {
    formJSON() {
      let formObject = {
        first: this.$refs.first.value,
        last: this.$refs.last.value,
        phone: this.$refs.phone.value,
        email: this.$refs.email.value,
        message: this.$refs.message.value,
      };
      return JSON.stringify(formObject);
    },
    buttonText() {
      switch (this.formStatus) {
        case STATUS.SUCCESS:
          return this.loadedData.success;
        case STATUS.SENDING:
          return this.loadedData.sending;
        default:
          return this.loadedData.send;
      }
    },
  },
};
</script>

<style scoped lang="scss">

@mixin four-col-grid {
  display: grid;
  grid-template-columns: repeat(4, [col-start] minmax(0, 1fr));
  gap: $col-gap;
  padding: 0;
  grid-auto-rows: minmax(min-content, max-content);
}

.contact-panel {
  display: grid;
  grid-template-columns: repeat(12, [col-start] minmax(0, 1fr));
  gap: $col-gap;
  padding: 10rem $side-padding 0 $side-padding;

  * {
    color: white;
  }

  .description-container {
    grid-column: 2 / span 4;

    @include four-col-grid;

    .main-description {
      grid-column: 1 / span 4;

      p {
        white-space: pre-wrap;
      }
    }

    #raccoon {
      grid-column: 1 / span 4;
    }

    #left-address,
    #right-address {
      grid-column: span 2;
      white-space: pre-wrap;
    }

    #left-address {
      display: flex;
      align-items: flex-start;
    }
  }

  .main-form {
    grid-column: 8 / span 4;
    align-items: end;

    @include four-col-grid;
    gap: 1rem 2.5rem;

    * {
      height: min-content;
    }

    #first-name {
      grid-column: 1 / span 2;
      height: min-content;
    }

    #last-name {
      grid-column: 3 / span 2;
      height: min-content;
    }

    #phone,
    #email {
      grid-column: 1 / span 2;
    }

    #message {
      grid-column: span 4;
    }

    button {
      width: fit-content;
      grid-column: span 4;
    }

    .error {
      visibility: hidden;
      grid-column: 1 / span 4;
      color: red;
    }

    .shown {
      visibility: visible;
    }
  }
}

@media screen and (max-width: $small-screen) {
  .contact-panel {
    .description-container {
      grid-column: 2 / span 10;
    }

    .main-form {
      grid-column: 2 / span 10;
      margin-top: 5rem;
    }
  }
}

@media screen and (max-width: $xs-screen) {
  .contact-panel {
    .main-form {
      #first-name {
        grid-column: 1 / span 4;
        height: min-content;
      }

      #last-name {
        grid-column: 1 / span 4;
        height: min-content;
      }

      #phone,
      #email {
        grid-column: 1 / span 4;
      }
    }
  }
}
</style>

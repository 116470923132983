import { gql } from "graphql-tag";
import {
  POSITION_FRAGMENT,
  PROJECT_TITLE_FRAGMENT,
  PAGE_TITLE_FRAGMENT,
  SERVICE_TITLE_FRAGMENT,
  TEAMEMBER_TITLE_FRAGMENT,
} from "@/gql-operations/fragments";

export const HOME_QUERY = gql`
  query home {
    homePage: pages(where: { title: "Home" }) {
      nodes {
        homePageFields {
          content {
            title
            description
          }
        }
      }
    }
  }
`;

export const SERVICES_QUERY = gql`
  query services {
    services {
      nodes {
        title
        servicesFields {
          content {
            title
            subtitle
            description
            callToAction
          }
        }
      }
    }
  }
`;

export const MEMBERS_QUERY = gql`
  query members {
    teamMembers {
      nodes {
        teamMemberFields {
          content {
            name
            title
            linkedinUrl
            description
            images {
              image {
                sourceUrl
                srcSet
                altText
              }
            }
          }
        }
      }
    }
  }
`;

export const PROJECTS_QUERY = gql`
  query projects {
    projects {
      nodes {
        title
        projectsFields {
          content {
            title
            companyTitle
            company
            yearTitle
            year
            typeTitle
            type
            description1
            bannerImage1 {
              sourceUrl
              altText
              srcSet
            }
            sections {
              subtitle
              description1
              image1 {
                sourceUrl
                altText
                srcSet
              }
              description2
              image2 {
                sourceUrl
                altText
                srcSet
              }
            }
            subtitle
            discoverBtn
            discoverUrl
            bannerImage2 {
              sourceUrl
              altText
              srcSet
            }
            callToAction1
          }
        }
      }
    }
  }
`;

// cannot put fragment in fragment as wpgraphql splits sharedPositionsFields in groups (e.g Page_sharedPositionsFields)
export const INITIAL_QUERY = gql`
  ${POSITION_FRAGMENT}
  ${PROJECT_TITLE_FRAGMENT}
  ${SERVICE_TITLE_FRAGMENT}
  ${TEAMEMBER_TITLE_FRAGMENT}
  ${PAGE_TITLE_FRAGMENT}
  query positions {
    teamMembers {
      nodes {
        title
        sharedPositionsFields {
          camera {
            ...positionFragment
          }
          target {
            ...positionFragment
          }
          next {
            ...pageTitleFragment
            ...serviceTitleFragment
            ...projectTitleFragment
            ...teammemberTitleFragment
          }
          previous {
            ...pageTitleFragment
            ...serviceTitleFragment
            ...projectTitleFragment
            ...teammemberTitleFragment
          }
          showRightPanel
          metaTitle
          metaDescription
        }
      }
    }
    services {
      nodes {
        title
        sharedPositionsFields {
          camera {
            ...positionFragment
          }
          target {
            ...positionFragment
          }
          next {
            ...pageTitleFragment
            ...serviceTitleFragment
            ...projectTitleFragment
            ...teammemberTitleFragment
          }
          previous {
            ...pageTitleFragment
            ...serviceTitleFragment
            ...projectTitleFragment
            ...teammemberTitleFragment
          }
          showRightPanel
          metaTitle
          metaDescription
        }
      }
    }
    projects {
      nodes {
        title
        sharedPositionsFields {
          camera {
            ...positionFragment
          }
          target {
            ...positionFragment
          }
          next {
            ...pageTitleFragment
            ...serviceTitleFragment
            ...projectTitleFragment
            ...teammemberTitleFragment
          }
          previous {
            ...pageTitleFragment
            ...serviceTitleFragment
            ...projectTitleFragment
            ...teammemberTitleFragment
          }
          showRightPanel
          metaTitle
          metaDescription
        }
      }
    }
    teamPage: pages(where: { title: "Team" }) {
      nodes {
        title
        sharedPositionsFields {
          camera {
            ...positionFragment
          }
          target {
            ...positionFragment
          }
          next {
            ...pageTitleFragment
            ...serviceTitleFragment
            ...projectTitleFragment
            ...teammemberTitleFragment
          }
          previous {
            ...pageTitleFragment
            ...serviceTitleFragment
            ...projectTitleFragment
            ...teammemberTitleFragment
          }
          showRightPanel
          metaTitle
          metaDescription
        }
      }
    }
    introPage: pages(where: { title: "Introduction" }) {
      nodes {
        title
        introductionPageFields {
          introduction
          callToAction
          callToActionPhone
        }
        sharedPositionsFields {
          camera {
            ...positionFragment
          }
          target {
            ...positionFragment
          }
          next {
            ...pageTitleFragment
            ...serviceTitleFragment
            ...projectTitleFragment
            ...teammemberTitleFragment
          }
          previous {
            ...pageTitleFragment
            ...serviceTitleFragment
            ...projectTitleFragment
            ...teammemberTitleFragment
          }
          showRightPanel
          metaTitle
          metaDescription
        }
      }
    }
    homePage: pages(where: { title: "Home" }) {
      nodes {
        title
        sharedPositionsFields {
          camera {
            ...positionFragment
          }
          target {
            ...positionFragment
          }
          next {
            ...pageTitleFragment
            ...serviceTitleFragment
            ...projectTitleFragment
            ...teammemberTitleFragment
          }
          previous {
            ...pageTitleFragment
            ...serviceTitleFragment
            ...projectTitleFragment
            ...teammemberTitleFragment
          }
          showRightPanel
          metaTitle
          metaDescription
        }
      }
    }
    menus {
      nodes {
        menuFields {
          servicesTitle
          projectsTitle
          raccoonTitle
          callToAction
          socials {
            logo {
              sourceUrl
            }
            url
          }
        }
      }
    }
  }
`;

export const CONTACT_PAGE_QUERY = gql`
  query contactPage {
    pages(where: { title: "Contact" }) {
      nodes {
        contactPageFields {
          content {
            address
            companyName
            contact
            email {
              error
              label
              placeholder
            }
            first {
              error
              label
              placeholder
            }
            intro
            last {
              error
              label
              placeholder
            }
            message {
              error
              label
              placeholder
            }
            phone {
              error
              label
              placeholder
            }
            send
            sending
            success
            error
          }
        }
      }
    }
  }
`;

export const JOB_PAGE_QUERY = gql`
  query jobPage {
    pages(where: { title: "Job" }) {
      nodes {
        jobPageFields {
          content {
            intro
            sections {
              title
              description
            }
            address
            companyName
            contact
            first {
              label
              placeholder
              error
            }
            last {
              label
              placeholder
              error
            }
            phone {
              label
              placeholder
              error
            }
            email {
              label
              placeholder
              error
            }
            cv {
              label
              placeholder
              error
            }
            portfolio {
              label
              placeholder
              error
            }
            questionsTitle
            questions {
              question {
                label
                placeholder
                error
              }
            }

            send
            sending
            success
            error
          }
        }
      }
    }
  }
`;

export const TEAM_PAGE_QUERY = gql`
  query teamage {
    pages(where: { title: "Team" }) {
      nodes {
        title
        teamPageFields {
          content {
            callToAction
            callToAction2
            description1
            description2
            description3
            quote
            subtitle
            subtitle2
            title
            values {
              title
              description
            }
            valuesTitle
          }
        }
      }
    }
  }
`;

<template>
  <button :disabled="disabled" :class="{ invert: invert, pulsating: pulsating }">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "RacButton",
  props: {
    text: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    invert: {
      type: Boolean,
      default: false,
    },
    pulsating: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
button {
  background-color: $orange;
  border: 1px solid $orange;
  padding: 1.6rem 1.6rem;
  border-radius: 3px;
  color: white;

  &:hover {
    cursor: pointer;
    background-color: transparent;
    color: $orange;
    transition: all 0.3s;
  }
  &:disabled {
    color: grey;
    background-color: white;
    border-color: grey;

    &:hover {
      color: grey;
    }
  }

  &.invert {
    background-color: transparent;
    color: $orange;
    &:hover {
      background-color: $orange;
      color: white !important;
      opacity: 1;
      transition: translate 0.3s;
    }
  }
}

.pulsating {
  animation-name: pulsating;
  animation-duration: 1.0s;
  animation-iteration-count: infinite;
}

@keyframes pulsating {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}
</style>

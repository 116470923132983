<template>
  <div
    v-if="loadedData"
    class="job-panel"
    id="job-panel"
  >
    <div class="description-container">
      <div
        v-for="section in loadedData.sections"
        :key="section.title"
        class="sections"
      >
        <h3 class="title">{{ section.title }}</h3>
        <div v-html="section.description" class="description" />
      </div>
    </div>
    <form class="main-form" @submit="checkForm">
      <div id="first-name">
        <RacInput
          ref="first"
          :label="loadedData.first.label"
          :placeholder="loadedData.first.placeholder"
          :error="loadedData.first.error"
        />
      </div>
      <div id="last-name">
        <RacInput
          ref="last"
          :label="loadedData.last.label"
          :placeholder="loadedData.last.placeholder"
          :error="loadedData.last.error"
        />
      </div>
      <div id="phone">
        <RacInput
          ref="phone"
          :label="loadedData.phone.label"
          :placeholder="loadedData.phone.placeholder"
          :error="loadedData.phone.error"
          :validation-regex="phoneRegex"
        />
      </div>
      <div id="email">
        <RacInput
          ref="email"
          :label="loadedData.email.label"
          :placeholder="loadedData.email.placeholder"
          :error="loadedData.email.error"
          :validation-regex="emailRegex"
        />
      </div>
      <div id="cv">
        <RacInput
          ref="cv"
          :label="loadedData.cv.label"
          :placeholder="loadedData.cv.placeholder"
          :error="loadedData.cv.error"
          :text-area="true"
        />
      </div>
      <div id="portfolio">
        <RacInput
          ref="portfolio"
          :label="loadedData.portfolio.label"
          :placeholder="loadedData.portfolio.placeholder"
          :error="loadedData.portfolio.error"
          :text-area="true"
        />
      </div>
      <p id="motivations">{{ loadedData.questionsTitle }}</p>
      <hr id="line" />
      <div id="message">
        <RacInput
          v-for="(question, index) in loadedData.questions.map(
            (el) => el.question
          )"
          :key="question.label"
          :ref="'question' + index"
          :label="question.label"
          :placeholder="question.placeholder"
          :error="question.error"
          :text-area="true"
          :mandatory="true"
        />
      </div>
      <RacButton
        @click.prevent="validateAndSend"
        :text="buttonText"
        :disabled="formStatus === 2"
        :pulsating="formStatus === 1"
      />
      <p class="error p2" :class="{ shown: formStatus === 3 }">
        {{ loadedData.error }}
      </p>
      <h2 id="raccoon">{{ loadedData.companyName }}</h2>
      <div id="left-address">
        <img src="../../assets/icons/location_icon.svg" alt="location icon" />
        <p class="p2">
          {{ loadedData.address }}
        </p>
      </div>
      <p class="p2" id="right-address">
        {{ loadedData.contact }}
      </p>
    </form>
  </div>
</template>

<script>
import RacButton from "@/components/Atoms/RacButton";
import RacInput from "@/components/Molecule/RacInput";
import { JOB_PAGE_QUERY } from "@/gql-operations/allGqlOperations";

const STATUS = {
  IDLE: 0,
  SENDING: 1,
  SUCCESS: 2,
  ERROR: 3,
};

export default {
  name: "JobApplication",
  components: { RacInput, RacButton },
  apollo: {
    getData: {
      query: JOB_PAGE_QUERY,
      update(data) {
        this.loadedData = data.pages.nodes[0].jobPageFields?.content;
      },
    },
  },
  data() {
    return {
      loadedData: null,
      lowerPanelOpened: true,
      formStatus: STATUS.IDLE,
      phoneRegex: /(^\+|^0{2})\d{11}$|^0\d{9}$/,
      emailRegex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      urlRegex:
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
    };
  },
  methods: {
    validateAndSend() {
      if (this.formStatus === STATUS.SENDING) {
        return;
      }

      let allValid = true;
      for (let ref in this.$refs) {
        ref.includes("question")
          ? this.$refs[ref][0].validate()
          : this.$refs[ref].validate();

        if (!this.$refs[ref].isValid && !this.$refs[ref][0]?.isValid) {
          allValid = false;
        }
      }
      if (allValid) {
        this.formStatus = STATUS.SENDING;
        fetch(`${this.$store.state.websiteLink}/wp/wp-json/v1/job`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: this.formJSON,
        }).then((response) => {
          this.formStatus =
            response.status === 200 ? STATUS.SUCCESS : STATUS.ERROR;
        });
      } else {
        try {
          document.querySelector("#job-panel").scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
  computed: {
    formJSON() {
      let questionsAnswers = [];
      for (let ref in this.$refs) {
        if (ref.includes("question")) {
          questionsAnswers.push(this.$refs[ref][0].value);
        }
      }

      let formObject = {
        first: this.$refs.first.value,
        last: this.$refs.last.value,
        phone: this.$refs.phone.value,
        email: this.$refs.email.value,
        cv: this.$refs.cv.value,
        portfolio: this.$refs.portfolio.value,
        questions: JSON.stringify(questionsAnswers),
      };
      return JSON.stringify(formObject);
    },
    buttonText() {
      switch (this.formStatus) {
        case STATUS.SUCCESS:
          return this.loadedData.success;
        case STATUS.SENDING:
          return this.loadedData.sending;
        default:
          return this.loadedData.send;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@mixin four-col-grid {
  display: grid;
  grid-template-columns: repeat(4, [col-start] minmax(0, 1fr));
  gap: $col-gap;
  padding: 0;
  grid-auto-rows: minmax(min-content, max-content);
}

.job-panel {
  display: grid;
  grid-template-columns: repeat(12, [col-start] minmax(0, 1fr));
  gap: $col-gap;
  padding: 10rem $side-padding 5rem $side-padding;

  * {
    color: white;
  }

  .description-container {
    grid-column: 1 / span 8;

    @include four-col-grid;

    .sections {
      grid-column: 1 / span 4;
      .description {
        :deep(p) {
          color: white;
          strong {
            color: white;
          }
        }
        :deep(ul) {
          list-style: none;
          li::before {
            content: "\2022"; // unicode for bullet
            color: white;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
          li p {
            display: inline;
            strong {
              color: white;
            }
          }
        }
      }
    }
  }

  .main-form {
    grid-column: 9 / span 5;
    align-items: end;

    @include four-col-grid;
    gap: 1rem 2.5rem;

    * {
      height: min-content;
    }

    #first-name,
    #last-name {
      grid-column: span 2;
      height: min-content;
    }

    #phone,
    #email {
      grid-column: span 2;
    }

    #message,
    #cv,
    #portfolio,
    #motivations {
      grid-column: span 4;
    }

    #line {
      grid-column: span 4;
      width: 100%;
    }

    button {
      width: fit-content;
      grid-column: span 4;
    }

    .error {
      visibility: hidden;
      grid-column: 1 / span 4;
      color: red;
    }

    .shown {
      visibility: visible;
    }

    #raccoon {
      grid-column: 1 / span 4;
    }

    #left-address,
    #right-address {
      grid-column: span 2;
      white-space: pre-wrap;
    }

    #left-address {
      display: flex;
      align-items: flex-start;
    }
  }
}

@media screen and (max-width: $small-screen) {
  .job-panel {
    .description-container {
      grid-column: 2 / span 10;
    }

    .main-form {
      grid-column: 2 / span 10;
      margin-top: 5rem;
    }
  }
}

@media screen and (max-width: $xs-screen) {
  .job-panel {
    .main-form {
      #first-name {
        grid-column: 1 / span 4;
        height: min-content;
      }

      #last-name {
        grid-column: 1 / span 4;
        height: min-content;
      }

      #phone,
      #email {
        grid-column: 1 / span 4;
      }
    }
  }
}
</style>

<template>
  <canvas class="webgl" />
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { HemisphereLight } from "three";
import { RacCameraHelper } from "../../scripts/racClasses";
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as dat from "dat.gui";
import Stats from "three/examples/jsm/libs/stats.module";
// import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { gsap } from "gsap";

let canvas = null;
let camera = null;
let scene = null;
let renderer = null;
let sizes = null;
let controls = null;
let hemiLight;
let directionalLight;
let directionalLight2;
let debugUI;
let lightHelper;
let lightHelper2;
let racCameraHelper;
let stats;
let disableShadowMapOnNextFrame = false;
let redPointMesh;
let cameraFirstPositioning = true;

export default {
  name: "ThreeCanvas",
  data() {
    return {
      htmlElements: [],
    };
  },
  props: {
    scrollAccumulator: Number,
  },
  methods: {
    initializeScene: function () {
      /**
       * BASE
       */
      canvas = document.querySelector("canvas.webgl");
      scene = new THREE.Scene();

      /**
       * GENERATE SKY SPHERE
       */
      // const skyGeo = new THREE.SphereGeometry(15, 16, 16);
      // let skyMaterial = new THREE.ShaderMaterial({
      //   uniforms: {
      //     color1: {
      //       value: new THREE.Color("deepskyblue"),
      //     },
      //     color2: {
      //       value: new THREE.Color("white"),
      //     },
      //   },
      //   vertexShader: `
      //     varying vec2 vUv;
      //
      //     void main() {
      //       vUv = uv;
      //       gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
      //     }
      //   `,
      //   fragmentShader: `
      //     uniform vec3 color1;
      //     uniform vec3 color2;
      //
      //     varying vec2 vUv;
      //
      //     void main() {
      //
      //       gl_FragColor = vec4(mix(color1, color2, vUv.y), 1.0);
      //     }
      //   `,
      //   side: THREE.DoubleSide,
      // });
      // const skyMesh = new THREE.Mesh(skyGeo, skyMaterial);
      // skyMesh.position.set(0, 3, 0);
      // scene.add(skyMesh);

      /**
       * Background texture
       */
      const envCubeTextureLoader = new THREE.CubeTextureLoader();
      const environmentMap = envCubeTextureLoader.load([
        "/envmap/ouestblur.jpg", // ouest
        "/envmap/estblur.jpg", // est
        "/envmap/vide.jpg",
        "/envmap/vide.jpg",
        "/envmap/vide.jpg", //sud
        "/envmap/nordblur.jpg", //nord
      ]);
      environmentMap.encoding = THREE.sRGBEncoding;
      scene.background = environmentMap;

      /**
       * Adds a roof
       */
      const roofMesh = new THREE.Mesh(
        new THREE.PlaneGeometry(13, 13, 2, 2),
        new THREE.MeshBasicMaterial({ color: 0x555555, side: THREE.DoubleSide })
      );
      roofMesh.rotateX(Math.PI / 2);
      roofMesh.position.set(-1, 3.35, 0);
      scene.add(roofMesh);

      /**
       * Loading manager
       */
      const loadingManager = new THREE.LoadingManager(
        () => {
          this.$store.commit("modelHasLoaded");
        },
        () => {
          //We could implement a loading bar here if needed
        }
      );

      /**
       * PREPARE WHITEBOARD MATERIALS
       */
      const textureLoader = new THREE.TextureLoader(loadingManager);
      const matcapTexture = textureLoader.load("/matcaps/t9.jpg");
      const material = new THREE.MeshMatcapMaterial();
      material.matcap = matcapTexture;

      /**
       * LOAD MODEL WITH DRACO COMPRESSION
       */
      // const dracoLoader = new DRACOLoader();
      // dracoLoader.setDecoderPath("/draco2/");
      // const gltfLoader = new GLTFLoader(loadingManager);
      // gltfLoader.setDRACOLoader(dracoLoader);
      // let officeGroup = new THREE.Group();
      //
      // const whiteboardObjectNames = [
      //   "whiteboard001",
      //   "whiteboard002",
      //   "whiteboard003",
      // ];
      //
      // // no leading "." in url for gltf loader !
      // gltfLoader.load("/officeExport.gltf", (gltf) => {
      //   while (gltf.scene.children.length !== 0) {
      //     if (whiteboardObjectNames.includes(gltf.scene.children[0]?.name)) {
      //       gltf.scene.children[0].material = material;
      //     }
      //     gltf.scene.children[0].castShadow = true;
      //     gltf.scene.children[0].receiveShadow = true;
      //     officeGroup.add(gltf.scene.children[0]);
      //   }
      //   scene.add(officeGroup);
      // disableShadowMapOnNextFrame = true;
      // });

      /**
       * LOAD FBX MODEL
       */
      const fbxLoader = new FBXLoader();
      fbxLoader.load(
        "/untitled.fbx",
        (object) => {
          object.traverse(function (child) {
            if (child.isMesh) {
              child.castShadow = true;
              child.receiveShadow = true;
              // (child as THREE.Mesh).material = material
              if (child.material) {
                child.material.side = THREE.DoubleSide;
              }
            }
          });
          object.scale.set(0.01, 0.01, 0.01);
          scene.add(object);
          disableShadowMapOnNextFrame = true;
        },
        // (xhr) => {
        //   console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
        // },
        (progress) => {
          console.log(progress);
        },
        () => {
          console.error("There has been an error loading the 3D model");
          this.$store.commit("setLoadError", true);
        }
      );

      /**
       * CREATE LIGHTS
       */
      hemiLight = new HemisphereLight(
        "white", // bright sky color
        "darkslategrey", // dim ground color
        0.3 // intensity
      );
      scene.add(hemiLight);

      directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
      directionalLight.castShadow = true;
      directionalLight.shadow.mapSize.set(2048, 2048); // high shadowmap size ok as we disable shadowmap autoupdate
      directionalLight.shadow.camera.far = 15;
      directionalLight.shadow.camera.left = -7;
      directionalLight.shadow.camera.top = 7;
      directionalLight.shadow.camera.right = 7;
      directionalLight.shadow.camera.bottom = -7;
      directionalLight.position.set(-3.4, 7, 4.5);
      directionalLight.shadow.bias = -0.0;
      directionalLight.shadow.normalBias = 0.01;
      directionalLight.shadow.radius = 4;
      scene.add(directionalLight);

      directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.4);
      directionalLight2.position.set(-4.9, 4, -5.7);
      scene.add(directionalLight2);

      /**
       * CREATE CAMERA AND CONTROLS
       */
      const aspect = sizes.width / sizes.height;
      camera = new THREE.PerspectiveCamera(
        aspect < 0.75 ? 100 : 80,
        aspect,
        0.1,
        100
      );
      camera.position.set(1, 1, 1);
      scene.add(camera);

      controls = new OrbitControls(camera, canvas);

      controls.enableDamping = true;
      if (!this.$store.state.isDebug) {
        controls.enableZoom = false;
        controls.enablePan = false;
      }
      controls.target.set(0, 0, 0);

      /**
       * CREATE RENDERER
       */
      renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true,
        powerPreference: "high-performance",
      });
      renderer.setClearColor(0x65bff7, 0.7);
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.PCFShadowMap;
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      renderer.outputEncoding = THREE.sRGBEncoding;
    },
    animate() {
      if (this.$store.state.isDebug) {
        stats.begin();
        controls.update();
        renderer.render(scene, camera);
        stats.end();
      } else {
        controls.update();
        renderer.render(scene, camera);
      }

      if (disableShadowMapOnNextFrame) {
        renderer.shadowMap.autoUpdate = false;
        disableShadowMapOnNextFrame = false;
      }

      // Call tick again on the next frame
      window.requestAnimationFrame(this.animate);
    },
    generateDebugUi() {
      debugUI = new dat.GUI();
      debugUI.width = 500;
      debugUI
        .add(hemiLight, "intensity")
        .min(0.0)
        .max(1.0)
        .step(0.01)
        .name("Hemisphere light intensity");
      const hemiLightParams = { skyColor: 0xffffff, groundColor: 0x2f4f4f };
      debugUI
        .addColor(hemiLightParams, "skyColor")
        .name("Hemisphere Sky color")
        .onChange(() => {
          hemiLight.color.set(hemiLightParams.skyColor);
        });
      debugUI
        .addColor(hemiLightParams, "groundColor")
        .name("Hemisphere ground color")
        .onChange(() => {
          hemiLight.groundColor.set(hemiLightParams.groundColor);
        });
      debugUI
        .add(directionalLight, "intensity")
        .min(0.0)
        .max(5.0)
        .step(0.001)
        .name("Direct light 1 intensity");
      const directLightParams = { color: 0xffffff };
      debugUI
        .addColor(directLightParams, "color")
        .name("Direct light 1 color")
        .onChange(() => {
          directionalLight.color.set(directLightParams.color);
        });
      debugUI
        .add(directionalLight.position, "x")
        .min(-10)
        .max(10)
        .step(0.1)
        .name("Direct light 1 X")
        .onChange(() => {
          lightHelper.update();
        });
      debugUI
        .add(directionalLight.position, "y")
        .min(-10)
        .max(10)
        .step(0.1)
        .name("Direct light 1 Y")
        .onChange(() => {
          lightHelper.update();
        });
      debugUI
        .add(directionalLight.position, "z")
        .min(-10)
        .max(10)
        .step(0.1)
        .name("Direct light 1 Z")
        .onChange(() => {
          lightHelper.update();
        });

      debugUI
        .add(directionalLight.shadow, "bias")
        .min(-0.1)
        .max(0.1)
        .step(0.0001)
        .name("Shadow bias");
      debugUI
        .add(directionalLight.shadow, "normalBias")
        .min(-0.1)
        .max(0.1)
        .step(0.0001)
        .name("Shadow normal bias");

      debugUI
        .add(directionalLight.shadow, "radius")
        .min(0)
        .max(15)
        .step(0.1)
        .name("Shadow radius");

      debugUI
        .add(directionalLight2, "intensity")
        .min(0.0)
        .max(5.0)
        .step(0.001)
        .name("Direct light 2 intensity");
      const directLightParams2 = { color: 0xffffff };
      debugUI
        .addColor(directLightParams2, "color")
        .name("Direct light 2 color")
        .onChange(() => {
          directionalLight.color.set(directLightParams2.color);
        });

      debugUI
        .add(directionalLight2.position, "x")
        .min(-10)
        .max(10)
        .step(0.1)
        .name("Direct light 2 X")
        .onChange(() => {
          lightHelper2.update();
        });
      debugUI
        .add(directionalLight2.position, "y")
        .min(-10)
        .max(10)
        .step(0.1)
        .name("Direct light 2 Y")
        .onChange(() => {
          lightHelper2.update();
        });
      debugUI
        .add(directionalLight2.position, "z")
        .min(-10)
        .max(10)
        .step(0.1)
        .name("Direct light 2 Z")
        .onChange(() => {
          lightHelper2.update();
        });
      debugUI
        .add(redPointMesh.position, "x")
        .min(-10)
        .max(10)
        .step(0.01)
        .name("Red debug point X")
        .onChange(() => {
          controls.target.x = redPointMesh.position.x;
        });
      debugUI
        .add(redPointMesh.position, "y")
        .min(-10)
        .max(10)
        .step(0.01)
        .name("Red debug point Y")
        .onChange(() => {
          controls.target.y = redPointMesh.position.y;
        });
      debugUI
        .add(redPointMesh.position, "z")
        .min(-10)
        .max(10)
        .step(0.01)
        .name("Red debug point Z")
        .onChange(() => {
          controls.target.z = redPointMesh.position.z;
        });
      debugUI
        .add(camera.position, "x")
        .min(-10)
        .max(10)
        .step(0.01)
        .name("Camera X");
      debugUI
        .add(camera.position, "y")
        .min(-10)
        .max(10)
        .step(0.01)
        .name("Camera Y");
      debugUI
        .add(camera.position, "z")
        .min(-10)
        .max(10)
        .step(0.01)
        .name("Camera Z");
    },
    generateHelpers() {
      lightHelper = new THREE.DirectionalLightHelper(directionalLight);
      scene.add(lightHelper);
      lightHelper2 = new THREE.DirectionalLightHelper(directionalLight2);
      scene.add(lightHelper2);
      stats = new Stats();
      stats.showPanel(1); // 0: fps, 1: ms, 2: mb, 3+: custom
      document.body.appendChild(stats.dom);
      const axesHelper = new THREE.AxesHelper(5); //Red=X,Green=Y,Blue=Z
      scene.add(axesHelper);

      redPointMesh = new THREE.Mesh(
        new THREE.SphereBufferGeometry(0.05, 16, 16, 16),
        new THREE.MeshBasicMaterial({ color: 0xff0000 })
      );
      scene.add(redPointMesh);

      // const directionalLightCameraHelper = new THREE.CameraHelper(
      //   lights[1].shadow.camera
      // );
      // scene.add(directionalLightCameraHelper);
    },
    createResizeEvent() {
      window.addEventListener("resize", () => {
        // Update sizes
        sizes.width = window.innerWidth;
        sizes.height = window.innerHeight;

        // Update camera
        camera.aspect = sizes.width / sizes.height;
        camera.updateProjectionMatrix();

        // Update renderer
        renderer.setSize(sizes.width, sizes.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      });
    },
    generateTrips() {
      racCameraHelper = new RacCameraHelper(
        this.positions,
        camera,
        controls,
        this.$store
      );
    },
  },
  mounted() {
    sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    this.initializeScene();
    if (this.$store.state.isDebug) {
      this.generateHelpers();
      this.generateDebugUi();
    }
    this.createResizeEvent();
    this.generateTrips();
    this.animate();
  },
  computed: {
    positions() {
      return this.$store.state.positions;
    },
    currentPosition() {
      return this.$store.state.currentPosition;
    },
    repositionCamera() {
      return this.$store.state.repositionCamera;
    },
  },
  watch: {
    currentPosition(newIndex) {
      if (cameraFirstPositioning) {
        racCameraHelper.moveTowardsIndex(newIndex, 0);
        cameraFirstPositioning = false;
        this.$store.commit("cameraIsPositioned");
      } else {
        racCameraHelper.moveTowardsIndex(newIndex);
      }
    },
    repositionCamera(newValue) {
      if (newValue) {
        racCameraHelper.moveTowardsIndex(this.currentPosition, 1);
      }
    },
    "$store.state.showRightPanel"() {
      //right panel is 54vw
      const newSize = this.$store.state.showRightPanel
        ? window.innerWidth * 0.46
        : window.innerWidth;
      canvas = document.querySelector(".webgl");
      gsap.to(sizes, {
        duration: 1,
        width: newSize,
        onUpdate: function () {
          canvas.style.width = `${sizes.width}px`;
          camera.aspect = sizes.width / sizes.height;
          camera.updateProjectionMatrix();
        },
      });
    },
    scrollAccumulator(newValue) {
      const zoomOutFactor = newValue / 5_000;
      const duration = newValue === 0 ? 1 : 0.3;
      gsap.to(camera, {
        zoom: 1 - zoomOutFactor,
        duration: duration,
        onUpdate: function () {
          camera.updateProjectionMatrix();
        },
      });
      // camera.updateProjectionMatrix();
    },
  },
};
</script>

<style scoped lang="scss">
.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  width: 100%;
  height: 100%;
}
</style>

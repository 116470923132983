import { gsap } from "gsap";
import * as THREE from "three";

export const TypeOfPosition = {
  TeamMembers: "membre",
  Projects: "projets",
  Services: "services",
};

export class RacPosition {
  constructor(node, typeOfPosition) {
    this.title = node.title;
    this.camera = new THREE.Vector3(
      node.sharedPositionsFields.camera.positionsFields?.x,
      node.sharedPositionsFields.camera.positionsFields?.y,
      node.sharedPositionsFields.camera.positionsFields?.z
    );
    this.target = new THREE.Vector3(
      node.sharedPositionsFields.target.positionsFields?.x,
      node.sharedPositionsFields.target.positionsFields?.y,
      node.sharedPositionsFields.target.positionsFields?.z
    );
    this.visited = false;
    this.showRightPanel = node.sharedPositionsFields?.showRightPanel ?? false;
    this.next = node.sharedPositionsFields.next?.title;
    this.previous = node.sharedPositionsFields.previous?.title;
    this.metaTitle = node.sharedPositionsFields?.metaTitle;
    this.metaDescription = node.sharedPositionsFields?.metaDescription;
    this.typeOfPosition = typeOfPosition;
  }
}

export class RacCameraHelper {
  constructor(positions, camera, controls, store) {
    this.moveTowards = "";
    this.positions = positions;
    this.camera = camera;
    this.controls = controls;
    this.store = store;
    this.timeline = gsap.timeline();
  }

  moveTowardsIndex(index, duration = 3.5) {
    this.timeline.clear();
    this.moveTowards = index;
    const toX = this.positions[this.moveTowards].camera.x;
    const toY = this.positions[this.moveTowards].camera.y;
    const toZ = this.positions[this.moveTowards].camera.z;
    const tarX = this.positions[this.moveTowards].target.x;
    const tarY = this.positions[this.moveTowards].target.y;
    const tarZ = this.positions[this.moveTowards].target.z;

    const distanceToNewPosition = this.camera.position.distanceTo(
      new THREE.Vector3(toX, toY, toZ)
    );

    if (distanceToNewPosition < 0.2) {
      //opens right panel directly
      this.store.commit("positionReached", true);
    }

    this.timeline.to(this.camera.position, {
      x: toX,
      y: toY,
      z: toZ,
      duration: duration,
      onComplete: () => {
        this.store.commit("positionReached", true);
      },
    });
    gsap.to(this.controls.target, {
      x: tarX,
      y: tarY,
      z: tarZ,
      duration: duration,
    });
  }
}

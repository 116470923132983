import { gql } from "graphql-tag";

export const POSITION_FRAGMENT = gql`
  fragment positionFragment on Position {
    positionsFields {
      x
      y
      z
    }
  }
`;

export const PAGE_TITLE_FRAGMENT = gql`
  fragment pageTitleFragment on Page {
    title
  }
`;

export const SERVICE_TITLE_FRAGMENT = gql`
  fragment serviceTitleFragment on Service {
    title
  }
`;

export const PROJECT_TITLE_FRAGMENT = gql`
  fragment projectTitleFragment on Project {
    title
  }
`;

export const TEAMEMBER_TITLE_FRAGMENT = gql`
  fragment teammemberTitleFragment on TeamMember {
    title
  }
`;

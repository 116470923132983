import { createStore } from "vuex";
import { RacPosition } from "../scripts/racClasses";

export default createStore({
  state: {
    positions: [],
    teamMembers: null,
    services: null,
    projects: null,
    currentPosition: null,
    showRightPanel: false,
    repositionCamera: false,
    showLowerPanel: false,
    jobPanelIsActive: false,
    canScroll: true,
    isDebug: false,
    modelIsLoaded: false,
    cameraIsPositioned: false,
    loadError: false,
    isTouchDevice: false,
    websiteLink: "https://office.raccoon.ch",
  },
  getters: {
    getPositionMetaTitle(state) {
      const currentPosition = state.currentPosition;
      return (
        state.positions[currentPosition]?.metaTitle ||
        "Raccoon - Créez de la valeur grâce à l’expérience utilisateur"
      );
    },
    getPositionMetaDescription(state) {
      const currentPosition = state.currentPosition;
      return (
        state.positions[currentPosition]?.metaDescription ||
        "Connectez vos utilisateurs aux interfaces de vos sites web, applications mobiles et aux HMI de vos systèmes embarqués."
      );
    },
    getTeamMember: (state) => (requestedMemberName) => {
      if (state.teamMembers && state.teamMembers.length > 0) {
        const member = state.teamMembers.find((mem) => {
          return mem.name
            .toLowerCase()
            .includes(requestedMemberName.toLowerCase());
        });
        return member === null ? undefined : member;
      } else {
        throw "No members in store";
      }
    },
    getService: (state) => (requestedService) => {
      if (state.services && state.services.length > 0) {
        const service = state.services.find((serv) => {
          return serv.serviceTitle
            .toLowerCase()
            .includes(requestedService.toLowerCase());
        });
        return service === null ? undefined : service;
      } else {
        throw "No services in store";
      }
    },
    getProject: (state) => (requestedProject) => {
      if (state.projects && state.projects.length > 0) {
        const project = state.projects.find((proj) => {
          return proj.projectTitle
            .toLowerCase()
            .includes(requestedProject.toLowerCase());
        });
        return project === null ? undefined : project;
      } else {
        throw "No projects in store";
      }
    },
    positionIndexFromPath: (state) => (path) => {
      let page = path.split("/")[1];
      page = page ? page : "introduction";
      let parameter = path.split("/")[2] ?? "NOPARAMETER";
      parameter = parameter.replaceAll("_", " ");

      return state.positions.findIndex((pos) => {
        return (
          pos.title.toLowerCase() === page.toLowerCase() ||
          pos.title.toLowerCase() === parameter.toLowerCase()
        );
      });
    },
  },
  mutations: {
    setTeamMembers(state, members) {
      if (
        state.teamMembers === null ||
        state.teamMembers?.length < members.length
      ) {
        state.teamMembers = members;
      }
    },
    setServices(state, services) {
      if (state.services === null || state.services?.length < services.length) {
        state.services = services;
      }
    },
    setProjects(state, projects) {
      if (state.projects === null || state.projects?.length < projects.length) {
        state.projects = projects;
      }
    },
    addPosition(state, position) {
      if (position instanceof RacPosition) {
        state.positions.push(position);
      }
    },
    goToPositionName(state, path) {
      const newIndex = this.getters.positionIndexFromPath(path);

      if (newIndex === -1) {
        return;
      }

      if (state.currentPosition === newIndex) {
        state.repositionCamera = true;
      }

      state.currentPosition = newIndex;
      state.showRightPanel = false;
      state.canScroll = false;
    },
    positionReached(state, isReached) {
      if (isReached) {
        state.positions[state.currentPosition].visited = true;
        if (state.positions[state.currentPosition].showRightPanel) {
          state.showRightPanel = true;
        } else {
          state.canScroll = true;
        }
        state.repositionCamera = false;
      }
    },
    closeRightPanel(state) {
      state.showRightPanel = false;
    },
    activateJobPanel(state, active) {
      state.jobPanelIsActive = active;
    },
    showLowerPanel(state, open) {
      state.showLowerPanel = open;
    },
    canScroll(state, allow) {
      state.canScroll = allow;
    },
    setToDebug(state) {
      state.isDebug = true;
    },
    modelHasLoaded(state) {
      state.modelIsLoaded = true;
    },
    cameraIsPositioned(state) {
      state.cameraIsPositioned = true;
    },
    setLoadError(state, hasLoadError){
      state.loadError = hasLoadError;
    },
    setIsTouchDevice(state, isTouchDevice) {
      state.isTouchDevice = isTouchDevice;
    },
  },
  actions: {},
  modules: {},
});

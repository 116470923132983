<template>
  <div class="leftpanel" :class="{ opened: leftPanelOpened }">
    <div
      class="burger burger1"
      :class="{ on: leftPanelOpened, rotated: leftPanelOpened }"
      @click="leftPanelOpened = !leftPanelOpened"
    >
      <span></span>
    </div>
    <div class="all-links">
      <h3 @click="openedSubMenu = TypeOfPosition.Services">
        {{ menuData.servicesTitle }}
      </h3>
      <hr />
      <Transition>
        <div
          v-show="openedSubMenu === TypeOfPosition.Services"
          class="submenu-container"
        >
          <router-link
            v-for="(position, index) in servicePositions"
            :key="position.title"
            class="submenu"
            :to="getRouterLink(TypeOfPosition.Services, position.title)"
            @click="leftPanelOpened = false"
          >
            <div class="circle" :class="{ visited: position.visited }" />
            <div
              class="line"
              :class="{
                visited: position.visited,
                firstline: index === 0,
                lastline: index === servicePositions.length - 1,
              }"
            ></div>
            <p class="p2 upper">{{ position.title }}</p>
          </router-link>
        </div>
      </Transition>
      <div @click="openedSubMenu = TypeOfPosition.Projects">
        <h3>{{ menuData.projectsTitle }}</h3>
        <hr />
      </div>
      <Transition>
        <div
          v-show="openedSubMenu === TypeOfPosition.Projects"
          class="submenu-container"
        >
          <router-link
            v-for="(position, index) in projectsPositions"
            :key="position.title"
            class="submenu"
            :to="getRouterLink(TypeOfPosition.Projects, position.title)"
            @click="leftPanelOpened = false"
          >
            <div class="circle" :class="{ visited: position.visited }" />
            <div
              class="line"
              :class="{
                visited: position.visited,
                firstline: index === 0,
                lastline: index === projectsPositions.length - 1,
              }"
            />
            <p class="p2 upper">{{ position.title }}</p>
          </router-link>
        </div>
      </Transition>
      <div @click="openedSubMenu = TypeOfPosition.TeamMembers">
        <h3>{{ menuData.raccoonTitle }}</h3>
        <hr />
      </div>
      <Transition>
        <div
          v-show="openedSubMenu === TypeOfPosition.TeamMembers"
          class="submenu-container"
        >
          <router-link
            to="/team"
            class="submenu"
            @click="leftPanelOpened = false"
          >
            <div
              class="circle"
              :class="{
                visited: positions.find((pos) => pos.title === 'Team')?.visited,
              }"
            />
            <div
              class="line firstline"
              :class="{
                visited: positions.find((pos) => pos.title === 'Team')?.visited,
              }"
            />
            <p class="p2 upper">{{ menuData.raccoonTitle }}</p>
          </router-link>
          <router-link
            v-for="(position, index) in teamMemberPositions"
            :key="position.title"
            class="submenu"
            :to="getRouterLink(TypeOfPosition.TeamMembers, position.title)"
            @click="leftPanelOpened = false"
          >
            <div class="circle" :class="{ visited: position.visited }" />
            <div
              class="line"
              :class="{
                visited: position.visited,
                lastline: index === teamMemberPositions.length - 1,
              }"
            />
            <p class="p2 upper">{{ position.title }}</p>
          </router-link>
        </div>
      </Transition>
      <RacButton
        :text="menuData.callToAction"
        @click="$store.commit('showLowerPanel', true)"
      />
      <div class="socials">
        <a
          v-for="social in menuData.socials"
          :key="social.url"
          :href="social.url"
          target="_blank"
          rel="noreferrer  noopener"
        >
          <img :src="social.logo.sourceUrl" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import RacButton from "../Atoms/RacButton";
import { TypeOfPosition } from "@/scripts/racClasses";

export default {
  name: "LeftPanel",
  components: { RacButton },
  mixins: [TypeOfPosition],
  props: {
    menuData: Object,
  },
  data() {
    return {
      leftPanelOpened: false,
      TypeOfPosition, //ENUM
      openedSubMenu: TypeOfPosition.Services,
      servicesLineHeight: 100,
    };
  },
  computed: {
    positions() {
      return this.$store.state.positions;
    },
    servicePositions() {
      return this.positions.filter(
        (pos) => pos.typeOfPosition === TypeOfPosition.Services
      );
    },
    projectsPositions() {
      return this.positions.filter(
        (pos) => pos.typeOfPosition === TypeOfPosition.Projects
      );
    },
    teamMemberPositions() {
      return this.positions.filter(
        (pos) => pos.typeOfPosition === TypeOfPosition.TeamMembers
      );
    },
  },
  methods: {
    getRouterLink(type, name) {
      return `/${type}/${name.toLowerCase().replaceAll(" ", "_")}`;
    },
    goToMember(name) {
      this.$router.push(`/members/${name.toLowerCase()}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/animations";
.leftpanel {
  position: absolute;
  top: 0;
  transform: translateX(
    calc(-#{$left-normal-width} + #{$left-closed-visible-width})
  );
  width: $left-normal-width;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  z-index: 2;
  transition: transform 1s;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: $side-padding;

  .burger {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2rem;

    width: 35px;
    height: 30px;
    border: none;
    background: transparent;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:focus {
      outline: none;
    }

    &:after,
    &:before,
    span {
      width: 100%;
      height: 2px;
      border-radius: 100px;
      position: absolute;
      left: 0px;
      background: white;
      transform: rotate(0deg);
      transition: all 0.5s;
    }

    &:after,
    &:before {
      content: "";
    }

    &:after {
      top: 0px;
      margin-top: 0px;
    }

    &:before {
      bottom: 0px;
      margin-bottom: 0px;
    }

    span {
      top: 50%;
      margin-top: -1px;
    }

    &.on {
      &:after {
        transform: rotate(135deg) translate(10px, -11px);
      }

      &:before {
        transform: rotate(225deg);
        bottom: 50%;
        margin-bottom: -2px;
      }

      span {
        transform: rotate(135deg) translate(0px, -1px);
      }
    }
  }

  .all-links {
    h3,
    h4,
    a,
    p,
    hr {
      color: white;
      cursor: pointer;
    }

    h3 {
      margin: 1rem 0;
    }

    p {
      margin: 0 2rem;
    }
    .submenu-container {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .submenu {
        z-index: 6;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 5px 0 5px 0;
        height: 22px;

        p {
          @include linkeffect(white);
          margin-left: 1rem;
        }

        .circle {
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background-color: white;
        }

        .line {
          position: absolute;
          transform: translateX(5px);
          width: 1px;
          height: 32px;
          background-color: white;
          z-index: 5;
        }

        .firstline {
          height: 16px;
          transform: translateY(8px) translateX(5px);
        }

        .lastline {
          height: 16px;
          transform: translateY(-8px) translateX(5px);
        }

        .visited {
          background-color: $orange;
        }
      }
    }

    button {
      margin-top: 3vh;
    }

    .socials {
      margin-top: 3vh;
      display: flex;
      gap: 1rem;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease-in;
  max-height: 230px;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  max-height: 0px;
}

.opened {
  transform: translateX(0);
}

@media screen and (max-width: $small-screen) {
  .leftpanel {
    width: $left-small-width;
    transform: translateX(
      calc(-#{$left-small-width} + #{$left-closed-visible-width})
    );
    padding-left: 3rem;
  }
  .opened {
    transform: translateX(0);
  }
}

@media screen and (max-width: $xs-screen) {
  .leftpanel {
    width: $left-xs-width;
    transform: translateX(0)
      translateY(calc(-100vh + #{$left-closed-visible-width}));
    padding-left: 3rem;

    .burger {
      top: initial;
      right: 50%;
      bottom: calc(#{$left-closed-visible-width} / 2);
      transform: translateY(50%) translateX(50%);
    }

    .rotated {
      top: 0.5rem;
    }
  }
  .opened {
    transform: translateX(0) translateY(0);
  }
}
</style>

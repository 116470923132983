import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import { createApolloProvider } from "@vue/apollo-option";

const httpLink = createHttpLink({
  // use absolute url
  uri: `${store.state.websiteLink}/wp/graphql`
});
const cache = new InMemoryCache(); // {addTypename: false} as option removes typename but creates error in positions / fragments
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
});

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

const app = createApp(App)
  .use(store)
  .use(router)
  .use(apolloProvider)
  .mount("#app");
router.app = app; //to access store in router

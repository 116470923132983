<template>
  <div class="introduction">
    <h2>{{ texts.introduction }}</h2>
    <h4 @click="$emit('goToNext')">{{ correctCTA }}</h4>
  </div>
</template>

<script>
export default {
  name: "IntroductionText",
  props: {
    texts: Object,
  },
  emits: ['goToNext'],
  computed: {
    correctCTA() {
      return this.$store.state.isTouchDevice
        ? this.texts.callToActionPhone
        : this.texts.callToAction;
    },
  },
};
</script>

<style scoped lang="scss">
.introduction {
  z-index: 2;
  position: absolute;
  left: 10rem;
  top: 50%;
  transform: translateY(-50%);
  max-width: 40vw;
  h2 {
    color: white;
    margin-bottom: 1rem;
  }
  h4 {
    color: $orange;
    transition: font-size 0.2s;
  }
}

@media screen and (max-width: $small-screen) {
  .introduction {
    max-width: 60vw;
  }
}

@media screen and (max-width: $xs-screen) {
  .introduction {
    left: initial;
    max-width: 100vw;
    margin: 0 2rem 0 2rem;

    h2 {
      font-size: clamp(3rem,1rem + 2.0833vmax,6.5rem); //35-50-65
      line-height: clamp(4.5rem,1rem + 3.0208vmax,8.16rem); //54.4-68-81.6
    }
  }
}
</style>

<template>
  <div class="input-container">
    <label :for="label">{{ label }}</label>
    <input
      v-if="!textArea"
      class="p2"
      v-model="value"
      type="text"
      :id="label"
      :placeholder="placeholder"
      autocomplete="off"
    />
    <textarea
      v-else
      class="p2"
      v-model="value"
      rows="6"
      :id="label"
      :placeholder="placeholder"
      autocomplete="off"
    />
    <p class="p2 error" :class="{hide : isValid}">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: "RacInput",
  props: {
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    validationRegex: {
      type: Object,
      default: new RegExp("(?:)"),
    },
    error: {
      type: String,
      default: "",
    },
    textArea: {
      type: Boolean,
      default: false,
    },
    mandatory: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      value: "",
      isValid: true,
    };
  },
  methods: {
    validate() {
      const minimalFieldLength = this.mandatory ? 1 : 0;
      this.isValid =
        (this.value.length >= minimalFieldLength) && this.validationRegex.test(this.value.trim());
    },
  },
};
</script>

<style scoped lang="scss">
.input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  label {
    color: white;
  }
  .error {
    color: red;
  }
  .hide {
    visibility: hidden;
  }
}
</style>

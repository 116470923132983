<template>
  <teleport to="head">
    <title>{{ $store.getters.getPositionMetaTitle }}</title>
    <meta
      name="description"
      :content="$store.getters.getPositionMetaDescription"
    />
  </teleport>
  <div class="main-wrapper">
    <transition name="fade">
      <LoadingAndErrorScreen v-if="showLoadingAndErrorScreen" />
    </transition>
    <transition name="fade">
      <IntroductionText
        v-if="showIntroductionText"
        :texts="introData"
        @click="goToNext"
      />
    </transition>
    <img
      class="logo"
      src="./assets/logos/raccoon_logo.svg"
      alt="Raccoon logo"
      @click="$router.push('/home')"
    />
    <LeftPanel :menu-data="menuData" />
    <RightPanel
      id="rightPanel"
      @goToPrevious="goToPrevious"
      @goToNext="goToNext"
    />
    <LowerPanel />
    <ThreeCanvas :scrollAccumulator="scrollAccumulator" />
  </div>
</template>

<script>
import ThreeCanvas from "./components/3D-views/ThreeCanvas";
import RightPanel from "./components/Panels/RightPanel";
import LeftPanel from "./components/Panels/LeftPanel";
import { ref } from "vue";
import { INITIAL_QUERY } from "@/gql-operations/allGqlOperations";
import { RacPosition, TypeOfPosition } from "@/scripts/racClasses";
import LoadingAndErrorScreen from "@/components/3D-views/LoadingAndErrorScreen";
import IntroductionText from "@/components/Molecule/IntroductionText";
import LowerPanel from "@/components/Panels/LowerPanel";

export default {
  components: {
    LowerPanel,
    IntroductionText,
    LoadingAndErrorScreen,
    // LowerPanel,
    LeftPanel,
    RightPanel,
    ThreeCanvas,
  },
  created() {
    if (document.location.href.split("#")[1] === "debug") {
      this.$store.commit("setToDebug");
    }
    if (window.location.pathname !== "/") {
      this.showIntroductionText = false;
    }
  },
  setup() {
    const rightPanel = ref(null);
    return {
      rightPanel,
    };
  },
  mounted() {
    if (!this.$store.state.isDebug) {
      window.addEventListener("wheel", this.handleScroll);
      window.addEventListener("scroll", () => {
        console.log("scrolled");
      });
    }

    const isTouchDevice =
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0;

    this.$store.commit("setIsTouchDevice", isTouchDevice);
  },
  methods: {
    handleScroll: function (scroll) {
      if (this.$store.state.showLowerPanel) {
        return;
      }
      if (this.$store.state.showRightPanel) {
        let rightPanel = document.querySelector("#rightPanel");
        if (
          rightPanel.offsetHeight + rightPanel.scrollTop >=
          rightPanel.scrollHeight
        ) {
          this.$store.commit("canScroll", true);
        } else if (scroll.deltaY < 0 && rightPanel.scrollTop === 0) {
          this.$store.commit("canScroll", true);
        } else {
          this.$store.commit("canScroll", false);
        }
      }
      if (!this.$store.state.canScroll) {
        return;
      }
      this.scrollAccumulator += scroll.deltaY; //1 scroll = +- 100
      clearTimeout(this.reduceScrollTimeout);
      this.reduceScrollTimeout = setTimeout(() => {
        this.scrollAccumulator = 0;
      }, 300);
      if (
        this.scrollAccumulator > this.neededScrollQuantity ||
        this.scrollAccumulator < -this.neededScrollQuantity
      ) {
        this.scrollAccumulator > 0 ? this.goToNext() : this.goToPrevious();
      }
    },
    goToPrevious() {
      const currentPosition = this.$store.state.currentPosition;
      const gotoPositionName =
        this.$store.state.positions[currentPosition].previous;
      if (!gotoPositionName) {
        return;
      }
      this.pushNextPositionToRouter(gotoPositionName);
    },
    goToNext() {
      const currentPosition = this.$store.state.currentPosition;
      const gotoPositionName =
        this.$store.state.positions[currentPosition].next;
      if (!gotoPositionName) {
        // we've reached the end of a track
        this.$store.commit("showLowerPanel", true);
        this.scrollAccumulator = 0;
        return;
      }
      this.pushNextPositionToRouter(gotoPositionName);
    },
    pushNextPositionToRouter(nextPositionName) {
      const nextPositionType = this.$store.state.positions.find(
        (pos) => pos.title === nextPositionName
      )?.typeOfPosition;

      let routerPushString = nextPositionType
        ? `/${nextPositionType}/${nextPositionName}`
        : `/${nextPositionName}`;
      routerPushString = routerPushString.toLowerCase().replaceAll(" ", "_");

      this.$router.push(routerPushString);
      this.scrollAccumulator = 0;
    },
    addNodeToPositions(node, typeOfPosition) {
      this.$store.commit("addPosition", new RacPosition(node, typeOfPosition));
    },
    goToInitialPosition() {
      const path = this.$route.path;

      //Do we have a position from path ?
      if (this.$store.getters.positionIndexFromPath(path) > 0) {
        this.$store.commit("goToPositionName", path);
      } else {
        //Try redirecting to "/"
        if (path !== "/") {
          this.showIntroductionText = true;
          this.$router.push("/");
          return;
        }
        //we shouldn't normally reach this code
        this.$store.commit("setLoadError", true);
        console.error(
          "There has been an error loading the initial position"
        );
      }
    }
  },
  data() {
    return {
      scrollAccumulator: 0,
      neededScrollQuantity: 700,
      reduceScrollTimeout: null,
      showScrollIndication: true,
      showIntroductionText: true,
      introData: {},
      menuData: {},
    };
  },
  apollo: {
    getData: {
      query: INITIAL_QUERY,
      update(data) {
        data.teamMembers.nodes.forEach((node) =>
          this.addNodeToPositions(node, TypeOfPosition.TeamMembers)
        );
        data.projects.nodes.forEach((node) =>
          this.addNodeToPositions(node, TypeOfPosition.Projects)
        );
        data.services.nodes.forEach((node) =>
          this.addNodeToPositions(node, TypeOfPosition.Services)
        );
        this.addNodeToPositions(data.introPage.nodes[0], null);
        this.addNodeToPositions(data.homePage.nodes[0], null);
        this.addNodeToPositions(data.teamPage.nodes[0], null);

        this.introData = data.introPage.nodes[0]?.introductionPageFields;

        this.menuData = data.menus.nodes[0]?.menuFields;

        setTimeout(() => {
          this.goToInitialPosition();
        }, 250);

        return data.ping;
      },
    },
  },
  computed: {
    showLoadingAndErrorScreen() {
      const loadError = this.$store.state.loadError;
      const cameraAndSceneReady =
        this.$store.state.cameraIsPositioned && this.$store.state.modelIsLoaded;

      return loadError || (!loadError && !cameraAndSceneReady);
    },
  },
  watch: {
    "$store.state.canScroll"() {
      this.showScrollIndication = false;
    },
    "$route.path"(newValue) {
      // user has scrolled so we remove the potentially showing intro text
      if(newValue !== '/') {
        this.showIntroductionText = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./assets/styles/cssNormalize.css";
@import "assets/styles/fonts";
@import "assets/styles/basestyles";
//Layout.scss not imported for now
//colors imported via vue.config.js (repeated in every component)

.main-wrapper {
  .logo {
    position: absolute;
    top: 1rem;
    left: 0;
    z-index: 10000;
    cursor: pointer;
    transform: scale(1.1);
  }

  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-leave-to {
    opacity: 0;
  }
}
</style>

<template>
  <div
    class="rightpanel"
    :class="{ closed: !this.$store.state.showRightPanel }"
  >
    <div class="close">
      <CloseButton @click="closeRightPanel" />
    </div>
    <router-view v-slot="{ Component }">
      <transition>
        <component :is="Component" :key="$route.name" @goToNext="$emit('goToNext')" />
      </transition>
    </router-view>
    <div class="control-buttons">
      <p
        @click="$emit('goToPrevious')"
        :class="{ inactive: !hasPreviousPosition }"
      >
        Précédent
      </p>
      <p @click="$emit('goToNext')">Suivant</p>
    </div>
  </div>
</template>

<script>
import CloseButton from "@/components/Atoms/CloseButton";

export default {
  name: "RightPanel",
  emits: ["goToPrevious", "goToNext"],
  components: {
    CloseButton,
  },
  props: {
    pagesData: Object,
  },
  methods: {
    closeRightPanel() {
      this.$store.commit("closeRightPanel");
      this.$store.commit("canScroll", true);
    },
  },
  computed: {
    hasPreviousPosition() {
      return this.$store.state.positions[this.$store.state.currentPosition]
        ?.previous;
    },
  },
  watch: {
    "$store.state.showRightPanel"(newValue) {
      if (newValue) {
        try {
          document.querySelector("#rightPanel").scrollTop = 0;
        } catch (e) {
          //
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/animations";
.rightpanel {
  position: absolute;
  right: 0;
  width: $right-normal-width;
  height: 100vh;
  background: white;
  z-index: 2;
  transition: transform 1s;
  overflow-y: scroll;
  overflow-x: hidden;

  .close {
    position: sticky;
    top: 3rem;
    z-index: 10;
    margin-left: calc(#{$right-normal-width} - 6rem);
  }

  .control-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 2rem 5rem 2rem;
    p {
      color: $orange;
      cursor: pointer;
      @include linkeffect(orange);
    }

    .inactive {
      color: grey;
      cursor: default;
      //disables link animation
      &:hover:before {
        transform: scaleX(0);
      }
    }
  }
}
.rightpanel::-webkit-scrollbar {
  width: 6px;
  background-color: white;
}

.rightpanel::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $orange;
}

.closed {
  transform: translateX($right-normal-width);
  transition: transform 1s;
}

@media screen and (max-width: $small-screen) {
  .rightpanel {
    width: $right-small-width;
    .close {
      margin-left: calc(#{$right-small-width} - 6rem);
    }
  }
  .closed {
    transform: translateX($right-small-width);
  }
}
@media screen and (max-width: $xs-screen) {
  .rightpanel {
    width: $right-xs-width;
    .close {
      margin-left: calc(#{$right-xs-width} - 6rem);
    }

    control-buttons {

    }
  }
  .closed {
    transform: translateX($right-xs-width);
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<template>
  <img src="../../assets/icons/orange_close_btn.svg" alt="Close Button" />
</template>

<script>
export default {
  name: "CloseButton",
};
</script>

<style scoped lang="scss">
img {
  background-color: rgba(255,255,255,0.6);
  backdrop-filter: blur(1px);
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
}
</style>
